<template>
  <div class="header">
    <v-card
      class="py-0 py-md-2 header-card"
      color="white"
      max-height="92"
      tile
      flat
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <div class="d-flex align-center">
                <v-dialog v-model="drawer" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="drawer = !drawer"
                      class="mt-n1 hidden-lg-and-up"
                      icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-img
                        alt="Bars"
                        class="bars"
                        src="@/assets/img/public/shared/header/bars.svg"
                        max-width="24"
                        contain
                      />
                    </v-btn>
                  </template>

                  <v-card
                    class="nav-bar d-flex flex-column"
                    color="white"
                    width="254"
                    tile
                    flat
                  >
                    <!-- Top -->
                    <div>
                      <!-- Close -->
                      <v-card
                        @click="drawer = !drawer"
                        class="pa-4"
                        color="gray200"
                        width="100%"
                        height="48"
                        tile
                        flat
                      >
                        <div
                          class="d-flex justify-start align-center gray200 mt-n1"
                        >
                          <v-icon color="gray600" class="mr-2">close</v-icon>

                          <p
                            class="b-body-1 font-weight-medium ma-0 pa-0 gray800--text"
                          >
                            Close
                          </p>
                        </div>
                      </v-card>

                      <!-- My loan and Personal data-->
                      <v-list v-if="auth.loggedIn" width="100%" flat>
                        <!-- My loan -->
                        <v-list-item
                          @click="drawer = !drawer"
                          :to="{ name: 'DashboardMyLoanPage' }"
                          exact-active-class="bg-active"
                        >
                          <v-icon>$walletIcon</v-icon>

                          <h6 class="h6 ml-1">My loan</h6>
                        </v-list-item>

                        <!-- Personal data -->
                        <v-list-item
                          @click="drawer = !drawer"
                          :to="{ name: 'DashboardPersonalDataPage' }"
                          exact-active-class="bg-active"
                        >
                          <v-icon>$briefcaseIcon</v-icon>
                          <h6 class="h6 ml-1">Personal data</h6>
                        </v-list-item>
                      </v-list>

                      <!-- Need Help -->
                      <!-- <v-card
                        @click="setNeedHelp()"
                        class="pa-4 mt-n2"
                        color="white"
                        width="100%"
                        height="48"
                        tile
                        flat
                      >
                        <div class="d-flex justify-start align-center mt-n1">
                          <div class="d-flex justify-space-around mr-2">
                            <v-img
                              alt="envelope-r"
                              class="envelope-r"
                              src="@/assets/img/public/shared/header/envelope-r.svg"
                              contain
                              max-width="24"
                            />
                          </div>

                          <h6 class="h6 ma-0 pa-0 primary--text">Need help</h6>
                        </div>
                      </v-card> -->

                      <!-- divider -->
                      <v-img
                        alt="divider"
                        class="divider"
                        src="@/assets/img/public/shared/header/divider.svg"
                      />

                      <!-- About Lime and FAQs and Blog-->
                      <v-list class="mt-n2" width="100%" flat>
                        <!-- About Lime -->
                        <v-list-item
                          @click="drawer = !drawer"
                          :to="{ name: 'AboutLimePage' }"
                          exact-active-class="bg-active"
                        >
                          <h6 class="h6 ml-1">About Lime</h6>
                        </v-list-item>

                        <!-- FAQs -->
                        <v-list-item
                          @click="drawer = !drawer"
                          :to="{ name: 'FAQsPage' }"
                          exact-active-class="bg-active"
                        >
                          <h6 class="h6 ml-1">FAQs</h6>
                        </v-list-item>

                        <!-- Blog -->
                        <v-list-item
                          @click="drawer = !drawer"
                          :to="{ name: 'BlogPage' }"
                          exact-active-class="bg-active"
                        >
                          <h6 class="h6 ml-1">Blog</h6>
                        </v-list-item>
                      </v-list>
                    </div>

                    <v-spacer />

                    <!-- Bottom -->
                    <div v-if="auth.loggedIn">
                      <v-list width="100%" flat>
                        <!-- Username -->
                        <v-list-item exact-active-class="bg-active">
                          <v-icon>$userLIcon</v-icon>

                          <h6 class="b-body-1 ml-1">
                            {{
                              $helpers.formatTheHeaderUserFullname(
                                "sidebar",
                                `${auth.user.firstnames} ${auth.user.surname}`
                              )
                            }}
                          </h6>
                        </v-list-item>
                      </v-list>

                      <!-- Exit -->
                      <div class="px-4 py-3">
                        <div @click="logOut()" class="custom-btn">
                          <div class="d-flex align-center">
                            <v-img
                              alt="sign-out-alt-s"
                              class="sign-out-alt-s"
                              src="@/assets/img/public/shared/header/sign-out-alt-s.svg"
                              max-width="24"
                              contain
                            />

                            <h6 class="b-btn-text primary--text ml-2 mt-n1">
                              Exit
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-dialog>

                <router-link :to="{ name: 'HomePage' }">
                  <v-img
                    alt="Logo"
                    class="logo"
                    src="@/assets/img/public/shared/logos/logo.svg"
                    max-width="134"
                    contain
                  />
                </router-link>
              </div>

              <v-spacer></v-spacer>

              <div class="hidden-sm-and-down">
                <div class="d-flex">
                  <!-- About Lime -->
                  <router-link
                    class="text-decoration-none b-subtitle-1 py-1 px-4"
                    active-class="active"
                    :to="{ name: 'AboutLimePage' }"
                  >
                    About Lime
                  </router-link>

                  <!-- FAQs -->
                  <router-link
                    class="text-decoration-none b-subtitle-1 ml-2 py-1 px-4"
                    active-class="active"
                    :to="{ name: 'FAQsPage' }"
                  >
                    FAQs
                  </router-link>

                  <!-- Blog -->
                  <router-link
                    class="text-decoration-none b-subtitle-1 ml-2 py-1 px-4"
                    active-class="active"
                    :to="{ name: 'BlogPage' }"
                  >
                    Blog
                  </router-link>
                </div>
              </div>
              <v-spacer></v-spacer>

              <div class="d-flex align-center">
                <!-- Phone -->
                <div class="hidden-sm-and-down">
                  <div class="d-flex">
                    <a
                      href="tel:+27104426722"
                      class="text-decoration-none"
                      target="_blank"
                    >
                      <div class="d-flex align-center">
                        <v-img
                          alt="Phone"
                          class="phone"
                          src="@/assets/img/public/shared/header/phone.svg"
                          max-width="18"
                          contain
                        />

                        <h6 class="b-subtitle-1 ml-2 primary--text">
                          010 442 6722
                        </h6>
                      </div>
                    </a>
                  </div>
                </div>

                <!--  -->
                <div v-if="auth.loggedIn" class="d-flex align-center ml-md-10">
                  <router-link
                    :to="{ name: 'DashboardMyLoanPage' }"
                    class="text-decoration-none"
                  >
                    <h6 class="b-body-1 text-capitalize">
                      <!-- small -->
                      <div class="small-mobile">
                        {{
                          $helpers.formatTheHeaderUserFullname(
                            "small",
                            `${auth.user.firstnames} ${auth.user.surname}`
                          )
                        }}
                      </div>

                      <!-- medium -->
                      <div class="medium-mobile">
                        {{
                          $helpers.formatTheHeaderUserFullname(
                            "medium",
                            `${auth.user.firstnames} ${auth.user.surname}`
                          )
                        }}
                      </div>

                      <!-- large -->
                      <div class="large-mobile">
                        {{
                          $helpers.formatTheHeaderUserFullname(
                            "large",
                            `${auth.user.firstnames} ${auth.user.surname}`
                          )
                        }}
                      </div>

                      <!-- other -->
                      <div class="other">
                        {{
                          $helpers.formatTheHeaderUserFullname(
                            "other",
                            `${auth.user.firstnames} ${auth.user.surname}`
                          )
                        }}
                      </div>
                    </h6>
                  </router-link>

                  <v-img
                    alt="Sign Out Alt"
                    class="sign-out-alt mx-2"
                    src="@/assets/img/public/shared/header/vertical-divider.svg"
                    max-width="1"
                    contain
                  />

                  <!-- Log out -->
                  <v-btn @click="logOut()" icon>
                    <v-img
                      alt="Sign Out Alt"
                      class="sign-out-alt"
                      src="@/assets/img/public/shared/header/sign-out-alt.svg"
                      max-width="24"
                      contain
                    />
                  </v-btn>
                </div>

                <!-- Login Button -->
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <div
                      :to="{ name: 'LoginPage' }"
                      class="login-btn ml-10 pointer"
                      @click="loginButton()"
                    >
                      <div class="d-flex align-center">
                        <v-img
                          alt="Sign In Alt"
                          class="sign-in-alt"
                          src="@/assets/img/public/shared/header/sign-in-alt.svg"
                          max-width="24"
                          contain
                        />

                        <h6 class="b-button ml-2 primary--text">Log In</h6>
                      </div>
                    </div>
                  </div>

                  <router-link
                    :to="{ name: 'LoginPage' }"
                    class="text-decoration-none hidden-md-and-up"
                  >
                    <div class="d-flex align-center">
                      <v-img
                        alt="Sign In Alt"
                        class="sign-in-alt"
                        src="@/assets/img/public/shared/header/sign-in-alt.svg"
                        max-width="24"
                        contain
                      />

                      <h6 class="b-subtitle-1 ml-2 mr-2 primary--text">
                        Log In
                      </h6>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </v-col>

          <!-- Need help -->
          <v-dialog v-model="dialog" width="500">
            <v-img
              alt="live-agent"
              class="live-agent.png"
              src="@/assets/img/dashboard/pages/sidebar/live-agent.png"
              contain
              width="502"
            />
          </v-dialog>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "LimeLoansTheHeader",

  data() {
    return {
      drawer: false,
      group: null,

      dialog: false,
      fullName: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  mounted() {},

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      resetState: "auth/resetState",
    }),

    // Login Button
    loginButton() {
      this.$router.push({
        name: "LoginPage",
      });
    },

    // logOut
    logOut() {
      this.logOutUser();
      // this.$router.push("/");
    },

    // setNeedHelp
    setNeedHelp() {
      this.dialog = true;

      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .header-card {
    box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
      0px 2px 4px rgba(61, 61, 61, 0.06);
  }

  .active {
    background: #f5f5f5;
    border-radius: 16px;

    color: #3d3d3d;
  }

  @media only screen and (max-width: 768px) {
    .header-card {
      height: 52px;
      .logo {
        width: 80px;
      }
    }
  }
}

.navigation-drawer {
  z-index: 9999999;
}

.custom-btn {
  border: 1px solid #5d9922;
  border-radius: 4px;

  height: 48px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px 0px 24px;
  gap: 8px;
}

.nav-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.h6 {
  //styleName: 16 M/semibold;
  font-family: "Noto Sans Display";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.bg-active {
  background-color: white;
  color: #5d9922 !important;
  // box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
  //   0px 2px 4px rgba(61, 61, 61, 0.06);
  border-radius: 8px;
}

.login-btn {
  /* Button */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 142px;
  height: 48px;

  /* Primary Lime/500 */
  border: 1px solid #5d9922;
  border-radius: 4px;
}

@media only screen and (max-width: 320px) {
  .small-mobile {
    display: block;
  }
  .medium-mobile {
    display: none;
  }
  .large-mobile {
    display: none;
  }
  .other {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .small-mobile {
    display: none;
  }
  .medium-mobile {
    display: block;
  }
  .large-mobile {
    display: none;
  }
  .other {
    display: none;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .small-mobile {
    display: none;
  }
  .medium-mobile {
    display: none;
  }
  .large-mobile {
    display: block;
  }
  .other {
    display: none;
  }
}

@media (min-width: 426px) {
  .small-mobile {
    display: none;
  }
  .medium-mobile {
    display: none;
  }
  .large-mobile {
    display: none;
  }
  .other {
    display: block;
  }
}
</style>
